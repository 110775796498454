body {
    background: none; 
    display: block;
    justify-content: unset;
    align-items: unset;
}

.leaflet-container {
    width: 100%;
    height: 87vh !important;
}

.logoImage {
    position: absolute;
    width: 30vw;
    height: auto;
    top: 2.6% !important;
    left: 4.5% !important;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: none;
    border: none;
    box-shadow: none;
}

.leaflet-popup-close-button {
    display: none;
}

.leaflet-control-attribution{
    display: none;
}

.popupContainer{
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    padding: 20px;
    border: 0px solid #ccc;
    border-radius: 50px;
    background-color: #ffffff;
    box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.35);
}

.popupContainer input,
.popupContainer button {
    width: 90%;
    margin-bottom: 50%;
    padding: 10px;
    border-radius: 20px;
    border: none;
    background-color: #dddddd;
}

.popupContainer input:focus {
    outline: none;  /* removes the default outline */
    border: 2px solid #E23D2A;
}
.popupContainer button {
    font-weight: bold;
    width: 97% !important;
    color: #FFFFFF !important;
    background-color: #F0523E;
    border: none;
    font-size: 14px !important;
}

.popupContainer button:hover {
    background-color: #E23D2A !important;
}

.popupTitle{
    margin-top: 5vh;
    font-size: 24px;
}

.ReactModal__Overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 15px;
}

.ReactModal__Content {
    bottom: -10% !important;
    left: -6% !important;
    position: absolute;
    width: 91%; 
    height: 50%; 
    padding: 20px; 
    text-align: center;
    animation: slideUp 0.5s ease-out;
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

h2{
    margin-top: 10%;
}

.frameMap {
    background-color: white;
    padding-top: 13%;
    border-radius: 0;
    margin: 2%;
}

.menuIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
    z-index: 1000; /* Ensure the menu stays on top */
  }
  
  .dropdownMenu {
    position: absolute;
    right: 0;
    top: 6vh;
    width: 94.5vw;
    font-size: 16px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 999; /* Ensure dropdown is just below the menu icon */
  }
  
  .dropdownMenu div {
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
    text-align: center;
  }
  
  .dropdownMenu div:last-child {
    border-bottom: none;
  }
  
  .dropdownMenu div:hover {
    background-color: #f5f5f5;
  }
  