.accountPageBackground {
  margin: 0;
  width: 96vw;
  height: 100vh;
  background: url("../assets/cutty_bg.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  box-shadow: inset 0 10px 15px -10px rgba(0, 0, 0, 0.25);
}

.accountPageContainer {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  top: -10%;
  border: 0px solid #ccc;
  border-radius: 15px;
  background-color: #ffffff;
  position: relative; 
}  

.accountPageContainer p {
  font-size: 16px;
  margin-bottom: 10px;
}

.logoImage {
  position: absolute;
  width: 30vw;
  height: auto;
  top: 5%;
}
  
.backButton {
  position: absolute; 
  top: 4%; 
  left: 3%; 
  background: transparent;
  border: none; 
  cursor: pointer; 
  padding: 0; 
  margin: 0; 
  outline: none;
  transition: transform 0.3s ease; 
 }

.backButton:hover {
    transform: scale(1.4); 
 }  

.frameAccount {
  background-color: white;
  padding-top: 13%;
  border-radius: 0;
  margin: 2%;
}

.accountPageInput{
  width: 85%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 20px;
  border: none;
  background-color: #dddddd;
}

.inputContainer {
  display: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.profileIcon{
  width: 45px;
  height: auto;
}

.userDetails {
  font-size: large;
  display: flex;
  align-items: center; 
  gap: 10px;
  margin-bottom: 20px; 
}

.saveIcon {
  width: 20px;
  margin-left: 1%;
}

#changePassword {
  margin-bottom: 2%;
  margin-left: -48%;
  font-weight: bold;
}

.errorOrSuccess{
  color: #F0523E;
  font-weight: bold;
}

.leaveMessage{
  margin-top: 2%;
  font-size: 12px;
}

#deleteAccount{
  font-size: 12px;
  text-decoration: underline;
}
.confirmButton{
  font-weight: bold;
  width: 50px;
  margin-left: -1%;
  color: #FFFFFF;
  background-color: #F0523E !important;
  border: none;
  font-size: 14px;
  padding: 14px;
  border-radius: 20px;
  margin-right: 2%;
}