/* Leaderboard.css */
.frameLeaderboard{
    background-color: white;
    padding-top: 13%;
    border-radius: 0;
    margin: 2%;
}

.leaderboardBackground {
    background-color: white;
    width: 98vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .leaderboardContainer {
    width: 80%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .userEntry {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .rank {
    flex: 1;
    font-weight: bold;
  }
  
  .username {
    flex: 3;
    font-weight: bold;
  }
  
  .points {
    flex: 3;
  }
  .leaderboardText{
    font-size: 24px;
    font-weight: bold;
    margin-top: 16.5%;
  }