.App {
  text-align: center;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  width: auto;
  height: auto;
  position: static;
  z-index: 1;
  padding-top: env(safe-area-inset-top); /* Add padding to the top of the header */
  background-color: white; /* Set the background color to white */
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: 'Gotham';  
  src: url('assets/fonts/GothamRnd-Book.otf');  
  font-display: swap;  
}
body {
  font-family: 'Gotham', sans-serif;  
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.desktopPage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(assets/cutty_bg_desktop.png);
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
  position: fixed;
}

.desktopPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff; 
  border-radius: 40px; 
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35); 
  padding: 20px;
  width: 30%;
  height: 10%;
  margin-bottom: 8%;
}

.desktopPageText{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.desktopPageLowerText{
  font-size: 10px;
  text-align: center;
  margin-top: 4%;
  color: #a8a8a8;
}

.desktopPageFrame{
  background-color: white;
  padding-top: 4.5%;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.35); 
}

.logoImageDesktop {
  position: absolute;
  width: 10vw;
  height: auto;
  top: 1.8%;
  left: 4.5%;
}