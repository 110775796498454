.rewardsBackground {
    margin: 0;
    width: 96vw;
    height: 100vh;
    background: url("../assets/cutty_bg.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 10px 15px -10px rgba(0, 0, 0, 0.25)
}
.containerRewardPage{
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    height: 100vh;
    padding: 20px;
    border: 0px solid #ccc;
    border-radius: 50px;
    background-color: #ffffff;
    box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.35);
    margin-top: 30vh;
}
.containerReward {
    width: 90%;
    height: 50vh;
    margin: 5%;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start; 
    align-items: flex-start; 
    flex-direction: column;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
}

.node {
    width: 110%;
    height: 20%; 
    border: 2px solid gray;
    border-radius: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: row;
    background-color: #f0f0f0;
    margin-bottom: 5%; 
    margin-left: -5%;
}

.node img {
    height: 30px;
    width: 30px;
    margin-right: 20px;
}

.node span {
    display: block;
}
.frameRewards {
    background-color: white;
    padding-top: 13vw;
    margin: 2vw;
}
.activeNodeImage {
    width: 15vw;
    height: auto;
    margin-left: -60vw;
    object-fit: contain;
    display: block;
    position:absolute;
}
.activeNodeContainer{
    width: 85vw;
    margin-left: 1vw;
    margin-top: 20%;
    height: 15vh; 
    border: 1px gray;
    border-radius: 80px;
    display: flex;
    justify-content: center; 
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    flex-direction: row;
    background-color: #ffffff;
    margin-bottom: -45%; 
    box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.35);
}
.rewardProgressText{
    margin-bottom: 10%;
    margin-left: 1%;
    position: absolute;
}
.rewardProgressText2{
    margin-left: 6%;
    margin-top: 4%;
    font-size: 12px;
    color: #a8a8a8; 
}
.redeemButton{
    font-weight: bold;
    width: 110%;
    margin-left: -5%;
    color: #FFFFFF;
    background-color: #F0523E;
    border: none;
    font-size: 14px;
    padding: 14px;
    border-radius: 20px;
}
.locationText{
    margin-bottom: 7%;
    margin-left: 12.5%;
    font-weight: bold;
    position: absolute;
}
.locationDetailsText{
    font-size: 12px;
    color: #a8a8a8;
    margin-top: 10%;
}

.popupContent{
    margin-top: -2.5vh;
}

.redeemConfirm{
    font-weight: bold;
    width: 25vw;
    margin-right: 14vw;
    color: #FFFFFF;
    background-color: #F0523E;
    border: none;
    font-size: 14px;
    padding: 10px;
    border-radius: 20px;
}

.redeemCancel{
    font-weight: bold;
    width: 25vw;
    margin-left: -1%;
    color: #FFFFFF;
    background-color: #F0523E;
    border: none;
    font-size: 14px;
    padding: 10px;
    border-radius: 20px;
}

.redeemError{
    margin-top: 2vh;
    text-align: center;
}