.survey-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .survey-form {
    width: 100%;
    max-width: 375px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  .justin-header{
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;    
  }
  
  .question-block {
    margin-bottom: 20px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .modal-button {
    background-color: #E23D2A;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .modal-button:hover {
    background-color: #c12a1d;
  }

  .modal-video {
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
    border-radius: 10px;
  }

  .question-block label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
  
  input[type="radio"] {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  textarea {
    width: 95%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
  }
  
  .submit-block {
    display: flex;
    justify-content: center;
  }
  
  .submit-btn {
    background-color: #E23D2A;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #c12a1d;
  }
  