.createAccountBackground {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background: url("../assets/cutty_bg.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.createAccountContainer {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 0px solid #ccc;
  border-radius: 15px;
  background-color: #ffffff;
}

.createAccountContainer input,
.createAccountContainer button {
  width: 90%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 20px;
  border: none; 
  background-color: #dddddd;
}

.createAccountContainer button {
  font-weight: bold;
  width: 97% !important;
  color: #FFFFFF !important;
  background-color: #F0523E;
  border: none;
  font-size: 14px !important;
}

.createAccountContainer input:focus {
  outline: none;  /* removes the default outline */
  border: 2px solid #E23D2A;
}
.createAccountContainer input[type="date"]::placeholder {
  color: #a8a8a8 !important;
}
#birthDay{
  width: 71%;
}
#signUp{
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0.5%;
}
#signUp:hover {
  background-color: #E23D2A;  /* Darken on hover */
}
#backToLogin{
  font-size: 14px;
  color: #E23D2A;
  font-weight: bold;
}

.signUpHeader{
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

h2{
  margin-bottom: -2% !important;
  font-size: 20px;
}
h3{
  margin-bottom: 10% !important;
  font-size: 12px !important;
  color:#a8a8a8;
}
h4{
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 13px;
  color: #a8a8a8;
}
h5{
  color:#a8a8a8;
  font-weight: normal;
  margin-bottom: 4px;
  margin-top: 4px;
  font-size: 12px;
  text-align: left;
}
h6{
  color: red;
  margin-bottom: 0.5%;
  margin-top: 2%;
}
.logoImage {
  position: absolute;
  width: 30vw;
  height: auto;
  top: 5%;
}

.checkbox-group {
  margin-bottom: 10px;
}

.checkbox-group label {
  display: block;
  margin-bottom: 5px;
}

.checkbox-options {
  display: flex;
  gap: 10px;
}

.checkbox-options label {
  display: flex;
  align-items: center;
}