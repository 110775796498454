.signInBackground {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background: url("../assets/cutty_bg.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signInContainer {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  padding: 20px;
  border: 0px solid #ccc;
  border-radius: 15px;
  background-color: #ffffff;
}

.signInContainer input,
.signInContainer button {
  width: 90%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 20px;
  border: none;
  background-color: #dddddd;
}

.signInContainer input:focus {
  outline: none;  /* removes the default outline */
  border: 2px solid #E23D2A;
}

.signInContainer button {
  font-weight: bold;
  width: 97% !important;
  color: #FFFFFF !important;
  background-color: #F0523E;
  border: none;
  font-size: 14px !important;
}

.signInContainer button:hover {
  background-color: #E23D2A !important;
}

#createAccount{
  font-size: 15px;
}

#signIn{
  color: #FFFFFF;
  font-size: 15px;
}

#createAccount{
  margin-top: -8%;
  font-size: 14px;
  color: #E23D2A;
  font-weight: bold;
}

.logoImage {
  position: absolute;
  width: 30vw;
  height: auto;
  top: 5%;
}

.signInHeader{
  margin-top: 2%;
  margin-bottom: 3.5%;
  font-weight: bold;
}

h3{
  font-weight: normal;
  margin-top: 5%;
  margin-bottom: -3%;
  font-size: 15px !important;
  color: #a8a8a8;
}