.frameNodeRewards {
    background-color: white;
    padding-top: 13%;
    margin-top: 2vw;
  }

  .node-rewards-background{
    margin: 0;
    width: 100vw;
    height: 100vh;
    background: url("../assets/cutty_bg.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .node-rewards-container{
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    margin-bottom: 20vh !important;
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 0px solid #ccc;
    border-radius: 15px;
    background-color: #ffffff;
  }
  .node-rewards-header{
    font-size: 5vw;
    margin-bottom: 3vh;
  }