.frameQuestion {
    background-color: white;
    padding-top: 13%;
    border-radius: 0;
    margin: 2%;
  }

.node-updated-container {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 0px solid #ccc;
    border-radius: 15px;
    background-color: #ffffff;
    margin-bottom: 20vh;
    text-align: center;
  }

.node-updated-bg{
    margin: 0;
    width: 96.3vw;
    height: 100vh;
    background: url("../assets/cutty_bg.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.node-updated-dropdown{
    border-radius: 15px;
    height: 4vh;
    width: 50vw;
    text-align: center;
    width: 70vw;
}

.node-updated-dropdown option {
    background-color: white; /* Background color of options */
    color: black;
    text-align: center;
  }

.redeemNodeProgress{
    font-weight: bold;
    width: 50vw;
    margin-top: 2.5vh;
    color: #FFFFFF;
    background-color: #F0523E;
    border: none;
    font-size: 14px;
    padding: 11px;
    border-radius: 20px;
}

.node-question-header{
    font-size: 6vw;
    margin-bottom: 2vh;
}