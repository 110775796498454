.homePage {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/cutty_bg_desktop.png);
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    width: 100vw;
    height: 100vh;
    overflow: hidden !important;
    position: fixed;
  }
  
  .homePageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff; 
    border-radius: 40px; 
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35); 
    padding: 20px;
    width: 80vw;
    height: 25vw;
    margin-bottom: 8%;
  }
  
  .homePageText{
    font-size: 5vw;
    text-align: center;
    font-weight: bold;
  }
  
  .homePageLowerText{
    font-size: 3vw;
    text-align: center;
    margin-top: 4%;
    color: #a8a8a8;
  }
  
  .homePageFrame{
    background-color: white;
    padding-top: 13vw;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.35); 
  }
  
  .logoImageHome {
    position: absolute;
    width: 25vw;
    height: auto;
    top: 1.8%;
    left: 4.5%;
  }