.landingPageBackground {
    margin: 0;
    width: 100vw;
    height: 100vh;
    background: url("../assets/cutty_bg.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .landingPageContainer {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 0px solid #ccc;
    border-radius: 15px;
    background-color: #ffffff;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .landingButton {
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    background-color: #E23D2A;
    color: #FFFFFF;
    font-weight: bold;
    text-decoration: none;
  }
  
  .logoImage {
    position: absolute;
    left: 37%;
    width: 30vw;
    height: auto;
    top: 5%;
    z-index: 1000;
  }
  
  .landingVideo {
    max-width: 100%;
    height: auto;
    margin-bottom: 5%;
    border-radius: 8px;
  }