.icon-button {
    position: relative;
    width: 50px;
    height: 30px;
    background: none;
    border: none;
    cursor: pointer;
}

.icon-button img {
    position: absolute;  
    top: 5%;  
    left: 0;  
    width: 50px;
    height: 30px;
    transition: opacity 0.3s ease-in-out;
}

.bottomBarContainer {
    position: fixed; 
    bottom: 0; 
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1%; 
    width: 100%;
    height: 60px; 
    background-color: #ffffff;
    z-index: 2000;
    box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.35);
}
