.displayPageBackground {
    margin: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 10px 15px -10px rgba(0, 0, 0, 0.25);
}

.currentHeldNFT {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47.5%; 
    width: 85%;
    margin-bottom: 8%;
    margin-top: -13%;
}

.flip-card {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.8s;
    transform-origin: 50% 50%;
    margin-bottom: 5vh;
    margin-top: 3vh;
    transform: translate3d(0,0,0);
}
  
.flip-card.flipped {
    transform: rotateY(180deg);
}
  
.card-face {
    position: absolute;
    width: 100%;
    height: 110%;
    backface-visibility: hidden;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
  
.card-face.front {
    color: white;
    padding: 20px;
    border-radius: 15px;
    background: linear-gradient(to top right, lightpink, peachpuff);
    align-items: end;
    justify-content: flex-start;
}
  
.card-face.back {
    color: white;
    padding: 20px;
    border-radius: 15px;
    background: rgb(24, 24, 24);
    transform: rotateY(180deg);
}

.otherCuttyNFTs{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 87%;
    margin-top: 3%;
    margin-left: 1.5%;
}

.otherCuttyNFTsContainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row !important;
    font-size: 11px;
    width: 100%;
}

.otherCuttyNFTsItem{
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: left;
    width: 22%;
    max-width: 100px;
    padding: 15px;
    border: 0px solid #ccc;
    border-radius: 15px;
    background: #ececec;
    height: 60%;
    margin-right: 2%; 
}

.itemSphere{
    margin-top: 55%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(to top right, lightpink, peachpuff);
    flex-shrink: 0;
}

.otherCuttyNFTsItemText{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: -5%;
    width: 100%;
    font-weight: bold;
}
.otherCuttyNFTsItemText2{
    display: flex;
    color: #a8a8a8;
    font-size: 9px;
    margin-top: -24%;
    margin-left: -5%;
    width: 100%;
    font-weight: bold;
}

.digitalCollectablesText{
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 3%;
}

.cuttyCurrentNFT{
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    bottom: 2%;
    left: 2%;
}

.logoImage {
    position: absolute;
    width: 30vw;
    height: auto;
    top: 5%;
}

.frameWallet {
    background-color: white;
    padding-top: 15%;
    margin: 0%;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
}

.connectWalletButton{
    font-weight: bold;
    width: 97% !important;
    color: #FFFFFF !important;
    background-color: #F0523E;
    border: none;
    font-size: 14px !important;
    width: 90%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 20px;
}

h3{
    text-align: center;
}

.walletAddress{
    font-size: 11px;
    text-align: center;
}

.disconnectWalletContainer{
    margin-top: -8%;
    width: 50%;
}

.cuttyCardFlipText{
    position: absolute;
    font-weight: bold;
    font-size: 20px;
    top: 1%;
    right: 4%;
}

.rewardVideo{
    position: absolute;
    width: 75vw;
    border-radius: 15px;
    top: 9vw;
}

.previewRewardStaticImage{
    position: relative;
    width: 42px;
    border-radius: 100%;
    left: 8%;
    top: 1vw;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 1s ease-in;
}
  
.visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 1s ease-in;
}